<template>
	<div>
		<!-- table -->
		<v-data-table
			:headers="headers"
			:items="stockItems"
			:loading="loading"
			:items-per-page="15"
			:footer-props="{}"
			dense
			disable-sort
			class="elevation-3 rounded-medium"
		>
			<template v-slot:item.product="{ item }">
				{{isSeller ? item.product.name : item.name }}
			</template>
			
			<!-- price -->
			<template v-slot:item.price="{ item }">
				<div v-if="!editItemId || editItemId !== item.id">
					{{item.price || 0}}	
				</div>
				<v-text-field
					v-else
					v-model="editData.price"
					dense
					hide-details
					outlined
					type="number"
					hide-spin-buttons
					:loading="submitLoading"
					:error="isSubmitted && !editData.price"
					class="rounded-medium mx-auto"
					:style="{maxWidth: '100px'}"
					:rules="rules.number"
					@keydown.enter="submit"
				/>
			</template>
			
			<!-- quantity -->
			<template v-slot:item.quantity="{ item }">
				<div v-if="!editItemId || editItemId !== item.id">
					{{item.quantity || 0}}	
				</div>
				<v-text-field
					v-else
					v-model="editData.quantity"
					dense
					hide-details
					outlined
					type="number"
					hide-spin-buttons
					:loading="submitLoading"
					:error="isSubmitted && editData.quantity < 0"
					class="rounded-medium mx-auto"
					:style="{maxWidth: '100px'}"
					:rules="rules.number"
					@keydown.enter="submit"
				/>
			</template>
			
			<!-- delivered -->
			<template v-slot:item.deliveredTime="{ item }">
				<div v-if="!editItemId || editItemId !== item.id">
					{{item.deliveredTime || 0}}
				</div>
				<v-text-field
					v-else
					v-model.number="editData.deliveredTime"
					dense
					hide-details
					outlined
					type="number"
					hide-spin-buttons
					:loading="submitLoading"
					class="rounded-medium mx-auto"
					:style="{maxWidth: '100px'}"
					@keydown.enter="submit"
				/>
			</template>
			
			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div v-if="!editItemId || editItemId !== item.id">
					<v-btn
						color="primary"
						dark
						small
						class="ms-1 px-1 flex-shrink-1"
						min-width="20px"
						@click="
							editItemId = item.id;
							editData.productId = isSeller ? item.product.id : item.id;
							editData.price = item.price || 0;
							editData.quantity = item.quantity || 0;
							editData.productStockQuantity = item.quantity || 0;
							editData.deliveredTime = item.deliveredTime || 0;
						"
					><v-icon>mdi-pencil</v-icon></v-btn>
				</div>
				<div v-else>
					<v-btn
						color="success"
						dark
						small
						class="ms-1 px-1 flex-shrink-1"
						min-width="20px"
						@click="submit"
					><v-icon>mdi-check-circle-outline</v-icon></v-btn>

					<v-btn
						dark
						small
						class="ms-1 px-1 flex-shrink-1"
						min-width="20px"
						@click="
							editItemId = null;
							editData.productId = null;
							editData.productStockQuantity = null;
							editData.price = null;
							editData.quantity = null;
							editData.deliveredTime = null;
						"
					><v-icon>mdi-close-circle-outline</v-icon></v-btn>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { transactionType } from '@/helpers/enums'
import { mapState } from 'vuex'
import rules from '@/helpers/validation rules'

export default {
	name: 'Warehouse',

	data: () => ({
		rules,
		loading: false,
		submitLoading: false,
		isSubmitted: false,
		editItemId: null,

		editData: {
			productId: null,
			productStockQuantity: null,

			price: null,
			quantity: null,
			deliveredTime: null,
		}
	}),

	computed: {
		...mapState({
			self: state => state.auth.self,
			stockItems: state => state.warehouses.stockItems,
		}),

		headers() {
			return [
				{
					text: this.$t('headers.product'),
					value: 'product',
					align: 'center',
					class: 'primary--text',
					cellClass: 'primary--text',
				},
				{
					text: this.$t('headers.price'),
					value: 'price',
					align: 'center',
					width: 200,
				},
				{
					text: this.$t('headers.quantity'),
					value: 'quantity',
					align: 'center',
					width: 200,
				},
				{
					text: this.$t('headers.delivering-period'),
					value: 'deliveredTime',
					align: 'center',
					width: 240,
				},
				{
					text: this.$t('headers.actions'),
					value: 'actions',
					align: 'center',
					width: 200,
				},
			]
		},

		items() {
			return [
				{
					id: 1,
					price: 0,
					deliveredTime: '1:00',
					quantity: 2,
					product: { name: 'product 1', id: 1 }
				},
				{
					id: 2,
					price: 0,
					deliveredTime: '2:00',
					quantity: 4,
					product: { name: 'product 2', id: 2 }
				},
				{
					id: 3,
					price: 0,
					deliveredTime: '3:00',
					quantity: 7,
					product: { name: 'product 3', id: 3 }
				},
				{
					id: 4,
					price: 0,
					deliveredTime: '4:00',
					quantity: 3,
					product: { name: 'product 4', id: 4 },
				}
			]
		},

		isSeller() { return Boolean(this.self.sellerId); }
	},

	methods: {
		fetchStock() {
			this.loading = true;
			this.$store.dispatch('warehouses/fetchStock', { isAdmin: !this.isSeller }).finally(() => {
				this.loading = false;
			})
		},

		submit() {
			this.isSubmitted = true;
			if (!this.editData.price || this.editData.quantity < 0) return;
			this.isSubmitted = false;

			this.submitLoading = true;
			const transitionAmount = this.editData.quantity - this.editData.productStockQuantity;
			this.$store.dispatch('warehouses/updateProduct', {
				productId: this.editData.productId,
				price: this.editData.price,
				quantity: Math.abs(transitionAmount),
				type: transitionAmount > 0 ? transactionType.in : transactionType.out,
				deliveredTime: this.editData.deliveredTime
			}).then(() => {
				this.editItemId = null;
				this.fetchStock();
			}).finally(() => {
				this.submitLoading = false;
			})
		},
	},

	created() {
		this.fetchStock();
	},
	beforeDestroy() {
		this.$store.dispatch('warehouses/reset');
	}
}
</script>

<style>

</style>