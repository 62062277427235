import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		statics: {},
		advanceStatics: {},
	},

	actions: {
		fetchAll({ commit }, { fromDate, toDate }) {
			return axios.get('/statics', { params: { startDate: fromDate, endDate: toDate } })
				.then(({ data }) => {
					commit(types.STORE_STATICS, data);
					return { ...data, productsView: data.products_view, popularProduct: data.popular_product, recentProducts: data.recent_add_products };
				})
		},
		fetchAllAdvance({ commit }, { fromDate, toDate }) {
			return axios.get('/statics-chart', { params: { startDate: fromDate, endDate: toDate } })
				.then(({ data }) => {
					commit(types.STORE_ADVANCE_STATICS, data);
					return {};
				})
		},
		fetchCashBack({ commit, state }, { fromDate, toDate }) {
			return axios.get('/statics/cash-back', { params: { startDate: fromDate, endDate: toDate } })
				.then(({ data }) => {
					state.statics = { ...state.statics, ...data }
					return data;
				})
		},
	},

	mutations: {
		[types.STORE_STATICS](state, statics) {
			state.statics = { ...state.statics, ...statics, productsView: statics.products_view, popularProduct: statics.popular_product, recentProducts: statics.recent_add_products }
		},
		[types.STORE_ADVANCE_STATICS](state, advanceStatics) {
			state.advanceStatics = advanceStatics;
		}
	}
}