export const LOGIN = 'LOGIN'; 
export const LOGOUT = 'LOGOUT';

export const STORE_STATICS = 'STORE_STATICS';
export const STORE_ADVANCE_STATICS = 'STORE_ADVANCE_STATICS';

export const STORE_USERS = 'STORE_USERS';

export const STORE_SYSTEM_USERS = 'STORE_SYSTEM_USERS';

export const STORE_RATINGS = 'STORE_RATINGS';

export const STORE_SETS = 'STORE_SETS';

export const STORE_CATEGORIES = 'STORE_CATEGORIES';
export const STORE_HIERARCHY_CATEGORIES = 'STORE_HIERARCHY_CATEGORIES';

export const STORE_FILTERS = 'STORE_FILTERS';
export const STORE_HIERARCHY_FILTERS = 'STORE_HIERARCHY_FILTERS';

export const STORE_CUSTOM_FILTERS = 'STORE_CUSTOM_FILTERS';

export const STORE_KEYWORDS = 'STORE_KEYWORDS';

export const STORE_PUBLISHERS = 'STORE_PUBLISHERS';

export const STORE_SELLERS = 'STORE_SELLERS';

export const STORE_PRODUCTS = 'STORE_PRODUCTS';

export const STORE_NEWS = 'STORE_NEWS';

export const STORE_SERVICES = 'STORE_SERVICES';

export const STORE_CUSTOM_SERVICES = 'STORE_CUSTOM_SERVICES';

export const STORE_ORDERS = 'STORE_ORDERS';

export const STORE_STOCK = 'STORE_STOCK';

export const STORE_ALBUMS = 'STORE_ALBUMS';

export const STORE_VIDEOS = 'STORE_VIDEOS';

export const STORE_SLIDERS = 'STORE_SLIDERS';

export const STORE_PLANS = 'STORE_PLANS';

export const STORE_OFFERS = 'STORE_OFFERS';

export const STORE_COUPONS = 'STORE_COUPONS';

export const STORE_LOYALTY_POINTS = 'STORE_LOYALTY_POINTS';
export const STORE_LOYALTY_POINTS_SUMMARY = 'STORE_LOYALTY_POINTS_SUMMARY';
export const STORE_LOYALTY_POINTS_RESET = 'STORE_LOYALTY_POINTS_RESET';

export const STORE_BRANCHES = 'STORE_BRANCHES';

export const STORE_SUBSCRIPTIONS = 'STORE_SUBSCRIPTIONS';

export const STORE_COMPANY_VALUES = 'STORE_COMPANY_VALUES';

export const STORE_COMPANY_HISTORIES = 'STORE_COMPANY_HISTORIES';

export const STORE_ABOUT_US = 'STORE_ABOUT_US';

export const STORE_SEO = 'STORE_SEO';

export const STORE_FILE_MANGER_IMAGE = 'STORE_FILE_MANGER_IMAGE';

export const STORE_FILE_MANGER_FILES = 'STORE_FILE_MANGER_FILES';

export const RESET = 'RESET';