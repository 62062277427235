<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="categories"
			:ids="deleteItemId"
			@on-delete="fetchCategories"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Categories.the-category')}}
		</bee-delete-dialog>
		
		<!-- restore dialog -->
		<bee-restore-dialog
			v-model="restoreDialog"
			moduleType="categories"
			:ids="restoreItemId"
			@on-restore="fetchCategories"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase orange--text text--darken-1">{{$t('restore')}}</span> 
			{{$t('Categories.the-category')}}
		</bee-restore-dialog>

		<!-- add/edit dialog -->
		<v-dialog v-model="dialog" :width="useAttributeTypes ? 600 : 400" persistent>
			<v-card class="rounded-medium">
				<v-form ref="form" @submit.prevent="submit" :disabled="dialogLoading">
					<v-card-title
						class="justify-center text-uppercase text-h6"
						:class="{ 'mb-3': !multiLangInputs, 'pb-2': multiLangInputs }"
					>
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('Categories.category')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<!-- tabs -->
						<v-lang-tabs
							v-if="multiLangInputs"
							v-model="lang"
							:checked-langs="acceptedToSaveLangs"
							:disable-non-selected="dialogLoading"
							background-color="bgColor"
							class="mb-4"
							@change="updateFieldsFromDraft"
						/>

						<!-- inputs -->
						<v-row dense class="mt-0" :class="{ 'flex-column': !useAttributeTypes }">
							<!-- name -->
							<v-col :sm="useAttributeTypes ? 6 : null" cols="12">
								<v-text-field
									v-model="name"
									:label="$t('inputs.name')"
									outlined
									dense
									hide-details
									class="rounded-small"
									:rules="rules.required"
								></v-text-field>
							</v-col>

							<!-- set -->
							<v-col
								v-if="showSets && !useCategoryTree"
								:sm="useAttributeTypes ? 6 : null"
								cols="12"
							>
								<v-autocomplete
									v-model="setIds"
									:items="sets"
									item-text="name"
									item-value="id"
									:label="$t('inputs.set')"
									outlined
									dense
									multiple
									hide-details
									class="rounded-small"
									:rules="rules.requiredAsArray"
								></v-autocomplete>
							</v-col>
							
							<template v-if="useCategoryTree">
								<!-- summary -->
								<v-col :sm="useAttributeTypes ? 6 : null" cols="12">
									<v-text-field
										v-model="summary"
										:label="$t('inputs.summary')"
										outlined
										dense
										multiple
										hide-details
										class="rounded-small"
									></v-text-field>
								</v-col>

								<!-- parent categories -->
								<v-col :sm="useAttributeTypes ? 6 : null" cols="12">
									<v-autocomplete
										v-model="parentCategoryId"
										:items="categories.filter(c => !c.deletedAt)"
										item-text="name"
										item-value="id"
										:label="$t('inputs.parent-category')"
										outlined
										dense
										hide-details
										class="rounded-small"
									></v-autocomplete>
								</v-col>
							</template>
							
							<!-- image -->
							<v-col v-if="useUploadCategoryImage" cols="12" class="pt-0 mt-2">
								<bee-drop-img
									v-model="image"
									dashed
									border-width="2"
									:border-color="isSubmitted & !image ? '#f00' : '#ccc'"
									radius="11"
									height="180px"
									contain
									blurable
									clearable
									:disabled="dialogLoading"
									disable-file-picker
									@remove-image="fileManagerFiles.mainImage = {}"
									@click="$eventBus.$emit('file-manager', { images: true }, (url, file) => {
										image = url;
										fileManagerFiles.mainImage = file;
									})"
								>
									<template v-slot:drag-text>
										<span class="text-body-1 font-weight-medium">
											{{$t('drag-text')}}
										</span>
										<span class="text-caption mb-0">
											{{$t('supported-text')}}
										</span>
									</template>
								</bee-drop-img>
							</v-col>

							<!-- is-featured -->
							<v-col v-if="useFeatured.categories" cols="12">
								<v-switch
									v-model="isFeatured"
									:label="$t('inputs.is-featured')"
									hide-details
									class="mt-0 ms-2 mb-1 pt-0"
								></v-switch>
							</v-col>

							<!-- attributes -->
							<v-col v-if="useCategoryAttributes" cols="12">
								<div class="d-flex align-center">
									<span class="text-body-1 font-weight-bold">
										{{$t('attributes')}}
									</span>
									<v-btn
										icon
										:disabled="dialogLoading"
										@click="attributes.push({
											id: attributes.length,
											name: createObjectFromLangs(),
											type: attributeType.string,
											options: createObjectFromLangs({}, []),
											image: null,
											mainImage: null
										})"
									>
										<v-icon>mdi-plus-circle-outline</v-icon>
									</v-btn>
								</div>
								
								<!-- attributes -->
								<v-row
									v-for="(attribute, index) in attributes"
									:key="index"
									align="center"
									dense
									class="ma-0"
								>
									<!-- attribute image -->
									<v-col v-if="useUploadAttributeImage" cols="auto">
										<bee-drop-img
											v-model="attribute.image"
											dashed
											border-width="2"
											:border-color="isSubmitted & !attribute.image ? '#f00' : '#ccc'"
											radius="11"
											:height="60"
											:width="60"
											contain
											blurable
											clearable
											hide-details
											hide-image-name
											hide-drag-drop-text
											:disabled="dialogLoading"
											disable-file-picker
											@remove-image="fileManagerFiles.mainImage = {}"
											@click="$eventBus.$emit('file-manager', { images: true }, (url, file) => {
												attribute.image = url;
												attribute.mainImage = file;
											})"
										></bee-drop-img>
									</v-col>

									<!-- attribute name -->
									<v-col
										:cols="useUploadAttributeImage ? (!useAttributeTypes ? 8 : 6) : 11"
									>
										<v-text-field
											v-model="attribute.name[lang]"
											:label="$t('inputs.attribute-name')"
											outlined
											dense
											hide-details
											class="rounded-medium"
											:rules="rules.required"
										></v-text-field>
									</v-col>

									<!-- attribute type -->
									<v-col
										v-if="useAttributeTypes"
										sm="3"
										cols="12"
									>
										<v-autocomplete
											v-model="attribute.type"
											:label="$t('inputs.attribute-type')"
											:items="attributeTypes"
											item-text="name"
											item-value="id"
											outlined
											dense
											hide-details
											class="rounded-medium"
											:rules="rules.requiredAsNumber"
										></v-autocomplete>
									</v-col>

									<!-- remove -->
									<v-col cols="1">
										<v-btn
											icon
											:disabled="dialogLoading"
											@click="() => {
												attributes.splice(index, 1);
												attributes.forEach((c, i, arr) => c.id > index ? arr[i].id-- : null)
											}"
										>
											<v-icon>mdi-minus-circle-outline</v-icon>
										</v-btn>
									</v-col>

									<!-- attributes options -->
									<v-col
										v-if="useAttributeTypes && [
											attributeType.multiChoice,
											attributeType.multiOptions,
											attributeType.yesNoMultiChoice,
										].includes(attribute.type)"
										cols="12"
									>
										<span class="text-body-2">
											{{
												$t('Categories.$-options', {
													name: attribute.name[lang] || $t('Categories.attribute')
												})
											}}
										</span>
										<bee-attribute-input
											v-model="attribute.options[lang]"
											:type="attributeType.multiChoice"
											outlined
											dense
											hide-details
											:rules="
												attribute.options[lang].length === 0 ? rules.required : []
											"
											class="d-flex flex-wrap"
											:multiple-inputs-bind="(value, i) => ({
												label: $t('Categories.option') + ' ' + (i + 1),
												style: { width: '48%', maxWidth: '48%', margin: '3px' }
											})"
										/>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							class="rounded-small"
							:loading="dialogLoading"
							:disabled="dialogLoading"
						>
							{{$t('save')}}
						</v-btn>
						<v-btn
							@click="dialog = false"
							class="rounded-small"
							:disabled="dialogLoading"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="6" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Categories.add-new-category')}}
						</v-btn>
					</v-col>
					<v-spacer/>

					<!-- sort & search -->
					<v-col lg="4" md="6" cols="12" class="text-md-start text-center">
						<v-row class="align-center">
							<v-col sm cols="12">
								<v-text-field
									v-model="search"
									:label="$t('search')"
									hide-details
									outlined
									dense
									prepend-inner-icon="mdi-magnify"
									class="rounded-medium"
								></v-text-field>
							</v-col>
							<v-col sm="auto" cols="12">
								<v-btn
									:color="isFiltered ? null : 'primary'"
									class="rounded-small"
									@click="isFiltered =! isFiltered"
								>
									{{isFiltered ? $t('cancel') : $t('search')}}
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<!-- tree -->
		<v-card
			v-if="useCategoryTree"
			class="rounded-medium"
		>
			<v-card-title v-if="$vuetify.breakpoint.mdAndUp" class="py-0 px-2">
				<v-row class="flex-md-row flex-column align-center ma-0 ms-sm-8 text-body-2 font-weight-bold">
					<!-- name -->
					<v-col lg="3" class="py-2">{{$t('headers.name')}}</v-col>

					<!-- image -->
					<v-col v-if="useUploadCategoryImage" lg="2" class="py-2">{{$t('headers.image')}}</v-col>

					<!-- summary -->
					<v-col lg="5" class="py-2">{{$t('headers.summary')}}</v-col>

					<!-- actions -->
					<v-col lg="2" class="text-end py-2 pe-7">{{$t('headers.actions')}}</v-col>
				</v-row>
			</v-card-title>
			<v-progress-linear v-if="loading" indeterminate absolute/>
			<v-divider/>
			<v-card-text class="pa-0">
				<!-- :open="hierarchyCategories.map(c => c.id)" -->
				<v-treeview
					:items="loading ? [] : hierarchyCategories"
					transition
					dense
					expand-icon="mdi-chevron-down"
				>
					<template v-slot:label="{ item }">
						<v-row class="flex-md-row flex-column align-center tree-row ma-0">
							<!-- name -->
							<v-col lg="3" class="py-0">
								<span v-if="$vuetify.breakpoint.smAndDown" class="text-body-2 me-1">
									{{$t('headers.name')}}:
								</span>

								<span class="text-body-1 font-weight-medium primary--text">
									{{item.name}}
								</span>
							</v-col>

							<!-- image -->
							<v-col v-if="useUploadCategoryImage" lg="2" class="py-0">
								<span v-if="$vuetify.breakpoint.smAndDown" class="text-body-2 me-1">
									{{$t('headers.image')}}:
								</span>

								<span class="text-body-1 font-weight-medium">
									<v-img
										v-if="true"
										:src="item.image || require('@/assets/placeholder-image.png')"
										:height="45"
										:width="45"
										lazy-src="@/assets/blurred.png"
										class="rounded-medium"
									/>
									<template v-else-if="0">-</template>
								</span>
							</v-col>

							<!-- summary -->
							<v-col lg="5" class="py-0">
								<span v-if="$vuetify.breakpoint.smAndDown" class="text-body-2 me-1">
									{{$t('headers.summary')}}:
								</span>

								<p class="text-subtitle-2 main--text font-weight-medium mb-0 tree-row__summary">
									{{ item.summary| isAvailable | ellipsis(140) }}
								</p>
							</v-col>

							<!-- actions -->
							<v-col lg="2" class="text-md-end text-center py-0">
								<template v-if="!item.deletedAt">
									<tooltip :text="$t('tooltip.edit')">
										<v-btn
											color="blue darken-4"
											dark
											small
											class="px-1 flex-shrink-1"
											min-width="20px"
											@click="editItemId = item.id; dialog = true;"
										><v-icon>mdi-pencil</v-icon></v-btn>
									</tooltip>

									<tooltip :text="$t('tooltip.delete')">
										<v-btn
											color="red darken-1"
											dark
											small
											class="ms-1 px-1"
											min-width="20px"
											@click="deleteItemId = item.id; deleteDialog = true;"
										>
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</tooltip>
								</template>
								
								<tooltip v-else :text="$t('tooltip.restore')">
									<v-btn
										color="orange darken-1"
										dark
										small
										class="ms-1 px-1"
										min-width="20px"
										@click="restoreItemId = item.id; restoreDialog = true;"
									>
										<v-icon>mdi-restore</v-icon>
									</v-btn>
								</tooltip>
							</v-col>
						</v-row>
						<v-divider/>
					</template>
				</v-treeview>

				<p v-if="loading" class="text-center py-2 mb-0">
					{{$vuetify.lang.translator('dataIterator.loadingText')}}
				</p>
			</v-card-text>
		</v-card>

		<!-- table -->
		<v-data-table
			v-else
			:headers="headers"
			:items="loading ? [] : filteredCategories"
			:loading="loading"
			dense
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- image -->
			<template v-slot:item.image="{ item }">
				<v-img
					:src="item.image ? item.image : require('@/assets/blurred.png')"
					:height="45"
					:width="45"
					lazy-src="@/assets/blurred.png"
					transition="scale-transition"
					class="rounded-medium mx-auto"
				/>
			</template>

			<!-- sets -->
			<template v-slot:item.setsIds="{ item }">
				{{getSets(item) | isAvailable}}
			</template>

			<!-- action -->
			<template v-slot:item.actions="{ item }">
				<template v-if="!item.deletedAt">
					<tooltip :text="$t('tooltip.edit')">
						<v-btn
							color="blue darken-4"
							dark
							small
							class="px-1 flex-shrink-1"
							min-width="20px"
							@click="dialog = true; editItemId = item.id"
						><v-icon>mdi-pencil</v-icon></v-btn>
					</tooltip>
					<tooltip :text="$t('tooltip.delete')">
						<v-btn
							color="red darken-1"
							dark
							small
							class="ms-1 px-1"
							min-width="20px"
							@click="deleteDialog = true; deleteItemId = item.id"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</tooltip>
				</template>
				<tooltip v-else :text="$t('tooltip.restore')">
					<v-btn
						color="orange darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="restoreItemId = item.id; restoreDialog = true;"
					>
						<v-icon>mdi-restore</v-icon>
					</v-btn>
				</tooltip>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';
import { useCategoryTree, useCategoryAttributes, useFeatured, useUploadCategoryImage, useUploadAttributeImage, useAttributeTypes } from '@/configs/global.config'
import { sets } from '@/configs/routes.config';
import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config';
import { createObjectFromLangs } from '@/helpers/functions';
import langsDraft from '@/mixins/langs-draft';
import { attributeTypes, attributeType } from '@/helpers/enums';

export default {
	name: 'Categories',
	mixins: [langsDraft],

	data: () => ({
		dialog: false,
		editItemId: null,
		
		deleteDialog: false,
		deleteItemId: null,
		
		restoreItemId: null,
		restoreDialog: null,
		
		isSubmitted: false,

		attributeTypes,
		attributeType,

		// configs
		showSets: sets.show,
		useCategoryTree,
		useCategoryAttributes,
		useUploadAttributeImage,
		useAttributeTypes,
		useUploadCategoryImage,
		multiLangInputs,
		useFeatured,

		// file manager
		fileManagerFiles: {
			mainImage: {}
		},

		// filter
		search: null,
		isFiltered: false,

		// helpers
		loading: false,
		dialogLoading: false,
		lang: defaultLang,
		rules,

		items: [
			{
				id: '1',
				name: 'Applications',
				numberOfProducts: 1,
				summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ducimus tenetur rem molestiae totam explicabo ipsum deleniti.',
				image: require('@/assets/logo.png'),
				children: [
					{ id: '2', name: 'Calendar : app', numberOfProducts: 2 },
					{ id: '3', name: 'Chrome : app', numberOfProducts: 3 },
					{ id: '4', name: 'Webstorm : app', numberOfProducts: 4 },
				],
			},
			{
				id: '5',
				name: 'Documents',
				numberOfProducts: 5,
				image: require('@/assets/logo.png'),
				summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ducimus tenetur rem molestiae totam explicabo ipsum deleniti.',
				children: [
					{
						id: '6',
						name: 'vuetify',
						numberOfProducts: 6,
						children: [
							{
								id: '7',
								name: 'src',
								numberOfProducts: 21,
								children: [
									{ id: '8', name: 'index : ts', numberOfProducts: 8 },
									{ id: '9', name: 'bootstrap : ts', numberOfProducts: 9 },
								],
							},
						],
					},
					{
						id: '10',
						name: 'material2',
						numberOfProducts: 21,
						image: require('@/assets/logo.png'),
						summary: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem ducimus tenetur rem molestiae totam explicabo ipsum deleniti.',
						children: [
							{
								id: '11',
								name: 'src',
								numberOfProducts: 21,
								children: [
									{ id: '12', name: 'v-btn : ts', numberOfProducts: 12 },
									{ id: '13', name: 'v-card : ts', numberOfProducts: 13 },
									{ id: '14', name: 'v-window : ts', numberOfProducts: 14 },
								],
							},
						],
					},
				],
			}
		],

		// draft for languages 
		draft: {},
		draftNames: ['name', 'summary'],

		// data
		name: null,
		setIds: [],
		summary: null,
		parentCategoryId: null,
		attributes: [],
		image: null,
		isFeatured: null,
	}),

	watch: {
		dialog(val) {
			if (val) {
				if (this.editItemId) {
					if (multiLangInputs) {
						this.fetchCategory();
					} else {
						const item = this.getCategoryById(this.editItemId);
						this.name = item.name;
						this.setIds = item.setsIds;
						this.summary = item.summary;
						this.parentCategoryId = item.parentId;
						this.image = item.image;
						this.attributes = item.attributes.map((c, i) => ({
							id: i,
							name: { [defaultLang]: c.name },
							image: c.image.url,
							mainImage: {},
							type: c.type,
							options: { [defaultLang]: c.options || [] }
						}));
						this.isFeatured = item.isFeatured;
					}
				}
			} else {
				this.$refs.form.reset();
				this.resetDraft();
				this.image = null;
				this.editItemId = null;
				this.attributes = [];
				this.lang = defaultLang;
				this.isSubmitted = false;
			}
		}
	},

	computed: {
		...mapState({
			categories: state => state.categories.categories,
			hierarchyCategories: state => state.categories.hierarchyCategories,
			sets: state => state.sets.sets.filter(c => !c.deletedAt),
		}),

		...mapGetters({
			getCategoryById: 'categories/getCategoryById',
			getSetById: 'sets/getSetById',
		}),

		headers() {
			const headers = [
				{ text: this.$t('headers.name'), value: 'name', align: 'center', class: 'primary--text', cellClass: 'primary--text' },
				{ text: this.$t('headers.actions'), value: 'actions', align: 'center', sortable: false },
			];
			if (this.showSets) {
				headers.splice(1, 0, { text: this.$t('headers.sets'), value: 'setsIds', align: 'center' });
			}
			if (this.useUploadCategoryImage) {
				headers.splice(0, 0, { text: this.$t('headers.image'), value: 'image', align: 'center', sortable: false });
			}
			return headers
		},

		filteredCategories() {
			return this.filterByName(this.isFiltered, this.categories, this.search);
		},

		acceptedToSaveLangs() {
			const acceptedLangs = langs.reduce((res, a) => { res[a.key] = false; return res }, {})
			langs.forEach(lang => {
				if (this.draft.name[lang.key] && (!useCategoryTree || this.draft.summary[lang.key])) {
					acceptedLangs[lang.key] = true;
				}
			});
			return acceptedLangs;
		}
	},

	methods: {
		getSets(item) {
			if (item.setsIds && item.setsIds.length) {
				const names = [];
				item.setsIds.forEach(id => {
					const set = this.getSetById(id);
					if (set) names.push(set.name)
				});
				return names.join(', ');
			}
		},

		async submit() {
			this.isSubmitted = true;
			let state = this.useUploadCategoryImage ? this.image : true;
			if (useUploadAttributeImage) {
				for (let i = 0; i < this.attributes.length; i++) {
					const element = this.attributes[i];
					if (!element.image && !element.imagePath) {
						state = false;
						break;
					}
				}
			}
			
			if (this.$refs.form.validate() && state) {
				this.dialogLoading = true;

				const promises = [];
				let itemData = null;

				const submitRequest = (
					isDefaultOne,
					{ name, summary, lang },
					id = this.editItemId
				) => {
					const actionName = id ? 'update' : 'create';
					return this.$store.dispatch(`categories/${actionName}`, {
						id: id,
						name: name,
						setIds: this.setIds,
						categoryId: this.parentCategoryId,
						summary: summary,
						attributes: this.attributes.map(c => ({
							name: c.name[lang],
							image: isDefaultOne && typeof c.image !== 'string' ? c.image : null,
							imagePath:
								isDefaultOne && typeof c.image === 'string'
									? c.mainImage.publicPath
									: null,
							imageName:
								isDefaultOne && typeof c.image === 'string'
									? c.mainImage.fileName
									: null,
							options: c.options[lang]
						})),
						image:
							isDefaultOne && typeof this.image !== 'string'
								? this.image
								: null,
						imagePath:
							isDefaultOne && typeof this.image === 'string'
								? this.fileManagerFiles.mainImage.publicPath
								: null,
						imageName:
							isDefaultOne && typeof this.image === 'string'
								? this.fileManagerFiles.mainImage.fileName
								: null,
						isFeatured: this.isFeatured,
						acceptLanguage: lang
					})
				};

				const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang);
				for (let index = 0; index < filteredLangs.length; index++) {
					const lang = filteredLangs[index].key;

					const name = this.draft.name[lang];
					const summary = this.draft.summary[lang];

					const dataDueLang = { name, summary, lang };

					if (name) {
						if (!itemData) {
							await submitRequest(!itemData, dataDueLang).then(data => { itemData = data });
						} else {
							promises.push(submitRequest(false, dataDueLang, itemData.id));
						}
					}
				}

				Promise.all(promises)
					.then(() => {
						const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.$t('Categories.category') })
							: this.$t('$-is-created-successfully', { name: this.$t('Categories.category') });
						this.$eventBus.$emit('show-snackbar', false, message);

						this.fetchCategories();
						this.dialog = false;
					})
					.catch(() => {
						this.editItemId = itemData.id;
					})
					.finally(() => {
						this.dialogLoading = false;
					})
			}
		},

		fetchCategories() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('categories/fetchAll'),
				useCategoryTree ? this.$store.dispatch('categories/fetchAllAsHierarchy') : null
			]).finally(() => {
				this.loading = false;
			})
		},

		fetchCategory() {
			this.dialogLoading = true;
			
			const slug = this.getCategoryById(this.editItemId).slug;

			const promises = [];
			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('categories/fetchBySlug', { slug, acceptLanguage: c.key })
						.then(data => {
							if (index === 0) {
								this.setIds = data.setsIds;
								this.parentCategoryId = data.parentId;
								this.image = data.image;
								this.isFeatured = data.isFeatured;
							}
							this.draft.name[c.key] = data.name;
							this.draft.summary[c.key] = data.summary;
							
							// initialling attributes for first language
							if (!this.attributes.length) {
								this.attributes = data.attributes.map((attribute, i) => ({
									id: i,
									name: createObjectFromLangs({ [c.key]: attribute.name }),
									image: attribute.image.url,
									mainImage: {},
									type: attribute.type,
									options: { [c.key]: attribute.options || [] }
								}))

							// filling texts in attributes for other languages
							} else {
								for (let i = 0; i < data.attributes.length; i++) {
									this.attributes[i].name[c.key] = data.attributes[i].name;
								}
							}
						})
				)
			});
			
			Promise.all(promises).finally(() => {
				this.name = this.draft.name[this.lang];
				this.summary = this.draft.summary[this.lang];
				this.dialogLoading = false;
			});
		},

		// helpers
		createObjectFromLangs
	},

	created() {
		this.fetchCategories();
		if (this.sets.length === 0) {
			this.$store.dispatch('sets/fetchAll')
		}
	}
};
</script>

<style lang="scss" scoped>
.tree-row {
	&:hover {
		background-color: #eee;
	}
	&__summary {
		white-space: pre-line;
	}
}
</style>