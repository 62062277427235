import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		stock: [],
		stockItems: [],
	},

	actions: {
		fetchStock({ commit }, { isAdmin }) {
			return axios.get(
				!isAdmin ? '/warehouses/self' : '/products/admin-publish'
			).then(({ data }) => {
				const storeData =
					!isAdmin
						? { stock: data, productSeller: data.productSeller }
						: { stock: {}, productSeller: data };
				commit(types.STORE_STOCK, storeData);
				return storeData;
			});
		},
		
		updateProduct({ commit }, { productId, price, quantity, type, deliveredTime }) {
			return axios.post(`/products/${productId}/update-data`, {
				price, quantity, type, deliveredTime, _method: 'PUT'
			});
		},

		reset({ commit }) {
			commit(types.RESET);
		}
	},

	mutations: {
		[types.STORE_STOCK](state, { stock, productSeller }) {
			state.stock = stock;
			state.stockItems = productSeller;
		},

		[types.RESET](state) {
			state.stock = {};
			state.stockItems = [];
		},
	},

	getters: {

	}
}