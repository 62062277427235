// import * as show from './generated/pages'
import wizardConfigs from './wizard-configs';
const { pages } = wizardConfigs;

export const preview = {
	inPathName: pages.preview.route || 'preview',
	name: 'preview',
	show: pages.preview.show
}

export const login = {
	inPathName: 'login',
	name: 'login',
}

export const dashboard = {
	inPathName: '',
	name: 'dashboard',
	show: true
}

export const fileManager = {
	inPathName: pages.fileManager.route || 'file-manager',
	name: 'file-manager',
	show: pages.fileManager.show
}

export const sets = {
	inPathName: pages.sets.route || 'sets',
	name: 'sets',
	show: pages.sets.show
}

export const categories = {
	inPathName: pages.categories.route || 'categories',
	name: 'categories',
	show: pages.categories.show
}

export const category = {
	inPathName: pages.category.route || 'category',
	name: 'category',
	show: pages.category.show
}

export const filters = {
	inPathName: pages.filters.route || 'filters',
	name: 'filters',
	show: pages.filters.show
}

export const keywords = {
	inPathName: pages.keywords.route || 'keywords',
	name: 'keywords',
	show: pages.keywords.show
}

export const publishers = {
	inPathName: pages.publishers.route || 'publishers',
	name: 'publishers',
	show: pages.publishers.show
}

export const products = {
	inPathName: pages.products.route || 'products',
	name: 'products',
	show: pages.products.show
}

export const product = {
	inPathName: pages.product.route || 'product',
	name: 'product',
	show: pages.product.show,
	isAddEditPage: true
}

export const news = {
	inPathName: pages.news.route || 'news',
	name: 'news',
	show: pages.news.show
}

export const newsIndex = {
	inPathName: pages.newsIndex.route || 'news-index',
	name: 'news-index',
	show: pages.newsIndex.show,
	isAddEditPage: true
}

export const services = {
	inPathName: pages.services.route || 'services',
	name: 'services',
	show: pages.services.show
}

export const service = {
	inPathName: pages.service.route || 'service',
	name: 'service',
	show: pages.service.show,
	isAddEditPage: true
}

export const orders = {
	inPathName: pages.orders.route || 'orders',
	name: 'orders',
	show: pages.orders.show
}

export const order = {
	inPathName: pages.order.route || 'order',
	name: 'order',
	show: pages.order.show,
	isAddEditPage: false
}

export const stock = {
	inPathName: pages.stock.route || 'stock',
	name: 'stock',
	show: pages.stock.show
}

export const loyaltyPoints = {
	inPathName: pages.loyaltyPoints.route || 'loyalty-points',
	name: 'loyalty-points',
	show: pages.loyaltyPoints.show
}

export const plans = {
	inPathName: pages.plans.route || 'plans',
	name: 'plans',
	show: pages.plans.show
}

export const plan = {
	inPathName: pages.plan.route || 'plan',
	name: 'plan',
	show: pages.plan.show,
	isAddEditPage: true
}

export const coupons = {
	inPathName: pages.coupons.route || 'coupons',
	name: 'coupons',
	show: pages.coupons.show
}

export const offers = {
	inPathName: pages.offers.route || 'offers',
	name: 'offers',
	show: pages.offers.show
}

export const albums = {
	inPathName: pages.albums.route || 'albums',
	name: 'albums',
	show: pages.albums.show
}

export const videos = {
	inPathName: pages.videos.route || 'videos',
	name: 'videos',
	show: pages.videos.show
}

export const sliders = {
	inPathName: pages.sliders.route || 'sliders',
	name: 'sliders',
	show: pages.sliders.show
}

export const users = {
	inPathName: pages.users.route || 'users',
	name: 'users',
	show: pages.users.show
}

export const ratings = {
	inPathName: pages.ratings.route || 'ratings',
	name: 'ratings',
	show: pages.ratings.show
}

export const userInfo = {
	inPathName: pages.userInfo.route || 'user-info',
	name: 'user-info',
	show: pages.userInfo.show
}

export const affiliateMarketers = {
	inPathName: pages.affiliateMarketers.route || 'affiliate-marketers',
	name: 'affiliate-marketers',
	show: pages.affiliateMarketers.show
}

export const affiliateMarketer = {
	inPathName: pages.affiliateMarketer.route || 'affiliate-marketer',
	name: 'affiliate-marketer',
	show: pages.affiliateMarketer.show
}

export const sellers = {
	inPathName: pages.sellers.route || 'sellers',
	name: 'sellers',
	show: pages.sellers.show
}

export const systemUsers = {
	inPathName: pages.systemUsers.route || 'system-users',
	name: 'system-users',
	show: pages.systemUsers.show
}

export const systemUsersRoles = {
	inPathName: pages.systemUsersRoles.route || 'system-users-roles',
	name: 'system-users-roles',
	show: pages.systemUsersRoles.show
}

export const branches = {
	inPathName: pages.branches.route || 'branches',
	name: 'branches',
	show: pages.branches.show
}

export const branch = {
	inPathName: pages.branch.route || 'branch',
	name: 'branch',
	show: pages.branch.show,
	isAddEditPage: true
}

export const subscriptions = {
	inPathName: pages.subscriptions.route || 'subscriptions',
	name: 'subscriptions',
	show: pages.subscriptions.show
}

export const aboutUs = {
	inPathName: pages.aboutUs.route || 'about-us',
	name: 'about-us',
	show: pages.aboutUs.show
}

export const companyValues = {
	inPathName: pages.companyValues.route || 'company-values',
	name: 'company-values',
	show: pages.companyValues.show
}

export const companyValue = {
	inPathName: pages.companyValue.route || 'company-value',
	name: 'company-value',
	show: pages.companyValue.show,
	isAddEditPage: true
}

export const companyHistories = {
	inPathName: pages.companyHistories.route || 'company-histories',
	name: 'company-histories',
	show: pages.companyHistories.show
}

export const companyHistory = {
	inPathName: pages.companyHistory.route || 'company-history',
	name: 'company-history',
	show: pages.companyHistory.show,
	isAddEditPage: true
}

export const SEO = {
	inPathName: pages.SEO.route || 'seo',
	name: 'seo',
	show: pages.SEO.show
}